// Polyfills
import './modules/polyfills/polyfills';
// Types
import './types/globals';
import { DOT } from './classes/dot.class';
import { KeyValuePairs } from './types/common';
// Methods
import { syncSid } from './modules/sid/sync';
import { addLinkdecoration } from './modules/linkdecoration/linkdecoration';
import { runMarker } from './modules/marker/marker';
import { configure, _fireAfterCfgEvent } from './modules/config/config';
import { handleImpress } from './modules/impress/impress';
import { initIframeBridge } from './modules/utils/html/iframe';
// Constants
import { SERVICE_VARIANT } from './constants/index';

// IVA npm library
import '@iva/refresher';
import { startErrorHandling } from '@iva/error-reporter';
import { SID_UTILS } from '@iva/utils';

startErrorHandling({
  endpoint: 'dot-static',
  scriptName: 'dot-nano.js',
});

SID_UTILS.removeInvalidSidCookie();

class DOT_NANO extends DOT {
  constructor() {
    super(SERVICE_VARIANT.NANO);
    runMarker(this);
    syncSid(this);

    /* Include all additional modules */
    addLinkdecoration(this);
  }

  /**
   * Returns new DOT_NANO instance
   */
  public getNewInstance(): DOT_NANO {
    return new DOT_NANO();
  }

  /**
   * Configures DOT instance
   *
   * @param customConfig config data object
   * @param impressCallback called when impress is sent
   */
  public cfg(customConfig: KeyValuePairs<unknown>, impressCallback?: () => void): void {
    configure(this, customConfig);

    if (this._cfg.impress) {
      handleImpress(this, impressCallback);
    }

    if (this.configured) {
      return;
    }
    this.configured = true;

    _fireAfterCfgEvent(this);

    initIframeBridge(this);
  }
}

window.DOT = new DOT_NANO();
